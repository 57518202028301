<style lang="less" scoped>
	.content {
		background: #f5f5f5;
		
		.details_head {
			width: 1360px;
			height: 702px;
			background: #ffffff;
			margin: 0 auto;
			padding: 50px 195px;
			
			.head_image {
				position: relative;
				width: 970px;
				height: 562px;
				img{
					object-fit: contain;
				}
				
				.head_icon {
					// background: url("../../../assets/jinjiang.png") no-repeat;
					background-repeat: no-repeat !important;
					background-size: cover !important;
					width: 141px;
					height: 120px;
					font-size: 26px;
					color: #ffffff;
					text-shadow: 0px 2px 4px rgba(101, 101, 101, 0.5);
					position: absolute;
					left: 0;
					top: 0;
					text-align: left;
					padding: 18px 0 0 6px;
				}
			}

			.head_introduce {
				padding-top: 32px;

				.left {
					float: left;
					font-size: 28px;
				}

				.right {
					float: right;
					display: flex;
					text-align: right;
					padding-top: 10px;

					div {
						font-size: 18px;
						color: #666666;
						padding: 0 15px;

						span {
							color: #000000;
						}
					}

					div:nth-child(1) {
						border-right: #666666 solid 1px;
					}
				}
			}
		}

		.details_introduce {
			width: 1360px;
			background: #ffffff;
			margin: 0 auto;
			padding: 40px 195px;
			margin-top: 20px;
			text-align: left;

			.text {
				font-size: 18px;
				color: #666666;
				padding-top: 21px;
			}
		}

		.details_show {
			width: 1360px;
			background: #ffffff;
			margin: 0 auto;
			padding: 40px 195px 79px 195px;
			margin-top: 20px;
			text-align: left;

			.details_show_image {
				width: 970px;
				height: 542px;
				margin-top: 20px;
				img{
					object-fit: contain;
				}
			}
		}
	}

	.title {
		font-size: 28px;
	}
	.youxiu{
		background: url("../../../assets/youxiu.png") no-repeat;
	}
	.ruwei{
		background: url("../../../assets/ruwei.png") no-repeat;
	}
	.jinjiang{
		background: url("../../../assets/jinjiang.png") no-repeat;
	}
	.yinjiang{
		background: url("../../../assets/yinjiang.png") no-repeat;
	}
	.tongjiang{
		background: url("../../../assets/tongjiang.png") no-repeat;
	}
</style>

<template>
	<div class="content">
		<div class="details_head">
		<!-- <div class="details_head jinjiang"> -->
			<div class="head_image icon_image">
				<img :src="obj.en_img" />
				<template v-if="obj.cur_prize">
					<div class="head_icon" style="background-repeat: no-repeat;background-size: cover;" :style="{background: 'url(https://cultural-creation.oss-cn-beijing.aliyuncs.com'+ obj.cur_prize.image +')'}">
						<!-- <span>{{obj.obj.situation.en_prize}}</span> -->
						<span v-if="obj.cur_prize">{{obj.cur_prize.en_reward_name}}</span>
					</div>
				</template>
			</div>
			<div class="head_introduce">
				<div class="left">{{obj.en_name}}</div>
				<div class="right">
					<div>
						{{$t('onlineDisplay.author')}}：
						<span v-for="(item,index) in obj.author_lsit">{{item}}<span v-show="(index+1)!=obj.author_lsit.length">,</span></span>
					</div>
					<div>
						{{$t('onlineDisplay.track')}}：
						<span>{{obj.en_track}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="details_introduce">
			<div class="title">{{$t('onlineDisplay.introduce')}}</div>
			<div class="text">{{obj.en_describe}}</div>
		</div>
		<div class="details_show">
			<div class="title">{{$t('onlineDisplay.show')}}</div>
			<div v-for="(item,index) in obj.en_images" :key="index" class="details_show_image icon_image">
				<img :src="item" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				obj: {}
			}
		},
		mounted() {
			this.entries()
		},
		methods: {
			async entries() {
				try {
					let _this = this
					let params = {
						id: this.$route.query.id
					}
					let res = await this.$axios.get('entries/detail', {
						params: params
					});
					if (res.code == 1) {
						this.obj = res.data;
						if(res.data.en_img){
							this.obj.en_img = this.obj.en_img.split(',')[0]
						}
					}
				} catch (error) {
					console.log(error);
				}
			},
		}
	}
</script>
